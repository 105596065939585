/**
 * @generated SignedSource<<2f63982f33410f88ec922a60499e2509>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MouldStatusInput = "New" | "Operational" | "Other" | "Qualification" | "RampUp" | "Transit" | "Unknown" | "WornOut" | "%future added value";
export type TicketsPriorityInput = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "%future added value";
export type TicketsStatusInput = "Closed" | "InProgress" | "NotStarted" | "Released" | "%future added value";
export type MutationCreateHallMonitorScreenInput = {
  processId: string;
  sections: ReadonlyArray<MutationCreateHallMonitorScreenSectionInput>;
  title: string;
};
export type MutationCreateHallMonitorScreenSectionInput = {
  codings?: ReadonlyArray<string> | null;
  completedDate?: LocalTimestampFilterInput | null;
  mouldStatus?: ReadonlyArray<MouldStatusInput> | null;
  priority: ReadonlyArray<TicketsPriorityInput>;
  status?: ReadonlyArray<TicketsStatusInput> | null;
  title: string;
};
export type LocalTimestampFilterInput = {
  ge?: LocalTimestampInput | null;
  le?: LocalTimestampInput | null;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type HallMonitorCreateDialogMutation$variables = {
  createHallMonitorVariantInput: MutationCreateHallMonitorScreenInput;
};
export type HallMonitorCreateDialogMutation$data = {
  readonly createHallMonitorScreen: {
    readonly id: string;
    readonly process: {
      readonly hallMonitorScreens: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
      }> | null;
    } | null;
  };
};
export type HallMonitorCreateDialogMutation = {
  response: HallMonitorCreateDialogMutation$data;
  variables: HallMonitorCreateDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "createHallMonitorVariantInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "createHallMonitorVariantInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "HallMonitorScreen",
  "kind": "LinkedField",
  "name": "hallMonitorScreens",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HallMonitorCreateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HallMonitorScreen",
        "kind": "LinkedField",
        "name": "createHallMonitorScreen",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Process",
            "kind": "LinkedField",
            "name": "process",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HallMonitorCreateDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HallMonitorScreen",
        "kind": "LinkedField",
        "name": "createHallMonitorScreen",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Process",
            "kind": "LinkedField",
            "name": "process",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20bd3f0a74d2d418c936e259d5ed251e",
    "id": null,
    "metadata": {},
    "name": "HallMonitorCreateDialogMutation",
    "operationKind": "mutation",
    "text": "mutation HallMonitorCreateDialogMutation(\n  $createHallMonitorVariantInput: MutationCreateHallMonitorScreenInput!\n) {\n  createHallMonitorScreen(input: $createHallMonitorVariantInput) {\n    id\n    process {\n      hallMonitorScreens {\n        id\n        title\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92dfd5447eaf94f5db9c3a251e1e35e2";

export default node;
