/**
 * @generated SignedSource<<7b9acb51c7c0663c3a3029eb0a6652e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketPriority = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HallMonitorItem_priority$data = {
  readonly count: number;
  readonly value: TicketPriority;
  readonly " $fragmentType": "HallMonitorItem_priority";
};
export type HallMonitorItem_priority$key = {
  readonly " $data"?: HallMonitorItem_priority$data;
  readonly " $fragmentSpreads": FragmentRefs<"HallMonitorItem_priority">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HallMonitorItem_priority",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "PriorityFacet",
  "abstractKey": null
};

(node as any).hash = "0a0b59ebf320672db29ee5cb1c2e53e7";

export default node;
