import { FC, ReactNode, useRef } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useInterval } from '../utility/interval';

const REFRESH_INTERVAL_IN_SECONDS = 60;

export const PageErrorBoundary: FC<{
  children: ReactNode;
  onReset?(): void;
  fallbackComponent: React.ComponentType<FallbackProps>;
}> = (props) => {
  const { children, onReset, fallbackComponent } = props;
  const errorBoundary = useRef<ErrorBoundary>(null);
  useInterval(() => errorBoundary.current?.resetErrorBoundary(), 1000 * REFRESH_INTERVAL_IN_SECONDS);

  return (
    <ErrorBoundary onReset={onReset} FallbackComponent={fallbackComponent} ref={errorBoundary}>
      {children}
    </ErrorBoundary>
  );
};
