/**
 * @generated SignedSource<<a017c0a6090491def3a206ff014a1a11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MutationMouldAddDimmingInput = {
  cavityNumber: number;
  createdAt: string;
  expectedRemovalDescription?: string | null;
  mouldId: string;
  permanent: boolean;
  reason: string;
};
export type AddDimmingDialogMutation$variables = {
  input: MutationMouldAddDimmingInput;
};
export type AddDimmingDialogMutation$data = {
  readonly mouldAddDimming: {
    readonly __typename: "DimmingExistsForCavityError";
    readonly cavityNumber: number;
  } | {
    readonly __typename: "DimmingNeedsRemovalDateError";
    readonly message: string;
  } | {
    readonly __typename: "InvalidCavityNumberError";
    readonly cavityNumber: number;
  } | {
    readonly __typename: "MutationMouldAddDimmingSuccess";
    readonly data: {
      readonly __typename: "Mould";
      readonly dimmings: {
        readonly count: number | null;
        readonly details: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"TDDimmingDetails_dimmingDetails">;
        }> | null;
      } | null;
      readonly id: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type AddDimmingDialogMutation = {
  response: AddDimmingDialogMutation$data;
  variables: AddDimmingDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cavityNumber",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "InvalidCavityNumberError",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "DimmingNeedsRemovalDateError",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "DimmingExistsForCavityError",
  "abstractKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddDimmingDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldAddDimming",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DimmingInfo",
                    "kind": "LinkedField",
                    "name": "dimmings",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DimmingDetails",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TDDimmingDetails_dimmingDetails"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldAddDimmingSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddDimmingDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldAddDimming",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DimmingInfo",
                    "kind": "LinkedField",
                    "name": "dimmings",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DimmingDetails",
                        "kind": "LinkedField",
                        "name": "details",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "addedBy",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                  }
                                ],
                                "type": "Description",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastName",
                                    "storageKey": null
                                  }
                                ],
                                "type": "Employee",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v9/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expectedRemovalDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isPermanent",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "addedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reason",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldAddDimmingSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a05e99fd85d9441bb1cd169d08a0ebe5",
    "id": null,
    "metadata": {},
    "name": "AddDimmingDialogMutation",
    "operationKind": "mutation",
    "text": "mutation AddDimmingDialogMutation(\n  $input: MutationMouldAddDimmingInput!\n) {\n  mouldAddDimming(input: $input) {\n    __typename\n    ... on InvalidCavityNumberError {\n      cavityNumber\n    }\n    ... on DimmingNeedsRemovalDateError {\n      message\n    }\n    ... on DimmingExistsForCavityError {\n      cavityNumber\n    }\n    ... on MutationMouldAddDimmingSuccess {\n      data {\n        __typename\n        dimmings {\n          count\n          details {\n            ...TDDimmingDetails_dimmingDetails\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment DeleteDimmingDialog_dimmingDetails on DimmingDetails {\n  cavityNumber\n  isPermanent\n}\n\nfragment EditDimmingDialog_dimmingDetails on DimmingDetails {\n  id\n  cavityNumber\n  expectedRemovalDescription\n  isPermanent\n  reason\n}\n\nfragment TDDimmingDetails_dimmingDetails on DimmingDetails {\n  id\n  addedBy {\n    __typename\n    ... on Description {\n      description\n    }\n    ... on Employee {\n      firstName\n      lastName\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  cavityNumber\n  expectedRemovalDescription\n  isPermanent\n  addedAt\n  reason\n  ...DeleteDimmingDialog_dimmingDetails\n  ...EditDimmingDialog_dimmingDetails\n}\n"
  }
};
})();

(node as any).hash = "9e442f99a54e4283e14f7cd483031295";

export default node;
