/**
 * @generated SignedSource<<94baab931c31a4ac3f10f34aef96a6ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryTicketInput = {
  ticketNumber: number;
};
export type QueryEquipmentInput = {
  equipmentNumber: number;
};
export type EquipmentDocumentsInput = {
  documentGroup?: string | null;
};
export type TDDocumentsTabQuery$variables = {
  documentsInput: EquipmentDocumentsInput;
  equipmentInput: QueryEquipmentInput;
  skipEquipmentQuery: boolean;
  skipTicketQuery: boolean;
  ticketInput: QueryTicketInput;
};
export type TDDocumentsTabQuery$data = {
  readonly equipment?: {
    readonly data?: {
      readonly " $fragmentSpreads": FragmentRefs<"EquipmentDocumentList_equipment" | "EquipmentDocumentsGroupSearch_equipment">;
    };
  };
  readonly ticket?: {
    readonly data?: {
      readonly equipment: {
        readonly " $fragmentSpreads": FragmentRefs<"EquipmentDocumentList_equipment" | "EquipmentDocumentsGroupSearch_equipment">;
      } | null;
    };
  };
};
export type TDDocumentsTabQuery = {
  response: TDDocumentsTabQuery$data;
  variables: TDDocumentsTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentsInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equipmentInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipEquipmentQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipTicketQuery"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ticketInput"
},
v5 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "ticketInput"
  }
],
v6 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EquipmentDocumentsGroupSearch_equipment"
  },
  {
    "args": [
      {
        "kind": "Variable",
        "name": "documents",
        "variableName": "documentsInput"
      }
    ],
    "kind": "FragmentSpread",
    "name": "EquipmentDocumentList_equipment"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "equipmentInput"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "documentsInput"
  }
],
v12 = [
  (v8/*: any*/),
  {
    "kind": "TypeDiscriminator",
    "abstractKey": "__isEquipment"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DocumentGroup",
    "kind": "LinkedField",
    "name": "documentGroups",
    "plural": true,
    "selections": [
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v11/*: any*/),
    "concreteType": "DocumentsConnection",
    "kind": "LinkedField",
    "name": "documents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v11/*: any*/),
    "filters": [
      "input"
    ],
    "handle": "connection",
    "key": "DocumentList_documents",
    "kind": "LinkedHandle",
    "name": "documents"
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TDDocumentsTabQuery",
    "selections": [
      {
        "condition": "skipTicketQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "ticket",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "equipment",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "QueryTicketSuccess",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "skipEquipmentQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "equipment",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "QueryEquipmentSuccess",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TDDocumentsTabQuery",
    "selections": [
      {
        "condition": "skipTicketQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "ticket",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "equipment",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "QueryTicketSuccess",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "skipEquipmentQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "equipment",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "QueryEquipmentSuccess",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "912d8606adb0767244d1a38640c980ec",
    "id": null,
    "metadata": {},
    "name": "TDDocumentsTabQuery",
    "operationKind": "query",
    "text": "query TDDocumentsTabQuery(\n  $ticketInput: QueryTicketInput!\n  $equipmentInput: QueryEquipmentInput!\n  $documentsInput: EquipmentDocumentsInput!\n  $skipTicketQuery: Boolean!\n  $skipEquipmentQuery: Boolean!\n) {\n  ticket(input: $ticketInput) @skip(if: $skipTicketQuery) {\n    __typename\n    ... on QueryTicketSuccess {\n      data {\n        __typename\n        equipment {\n          __typename\n          ...EquipmentDocumentsGroupSearch_equipment\n          ...EquipmentDocumentList_equipment_115MU8\n          id\n        }\n        id\n      }\n    }\n  }\n  equipment(input: $equipmentInput) @skip(if: $skipEquipmentQuery) {\n    __typename\n    ... on QueryEquipmentSuccess {\n      data {\n        __typename\n        ...EquipmentDocumentsGroupSearch_equipment\n        ...EquipmentDocumentList_equipment_115MU8\n        id\n      }\n    }\n  }\n}\n\nfragment DocumentRow_document on Document {\n  id\n  description\n  type\n  link\n  name\n}\n\nfragment EquipmentDocumentList_equipment_115MU8 on Equipment {\n  __isEquipment: __typename\n  documents(first: 50, input: $documentsInput) {\n    edges {\n      node {\n        id\n        ...DocumentRow_document\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment EquipmentDocumentsGroupSearch_equipment on Equipment {\n  __isEquipment: __typename\n  documentGroups {\n    id\n    description\n  }\n}\n"
  }
};
})();

(node as any).hash = "0322a327169b3659402651760bd7018d";

export default node;
