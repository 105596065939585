/**
 * @generated SignedSource<<e7dbb101ccd7339621813dc4e942dec5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateTicketCard_equipment$data = {
  readonly data?: {
    readonly __typename: string;
    readonly hasQARejection?: boolean | null;
    readonly " $fragmentSpreads": FragmentRefs<"CreateTicketDescriptionStep_equipment" | "CreateTicketEquipmentStep_equipment" | "CreateTicketLocationStep_equipment" | "CreateTicketPriorityStep_equipment">;
  };
  readonly " $fragmentType": "CreateTicketCard_equipment";
};
export type CreateTicketCard_equipment$key = {
  readonly " $data"?: CreateTicketCard_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketCard_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateTicketCard_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasQARejection",
                  "storageKey": null
                }
              ],
              "type": "Mould",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreateTicketEquipmentStep_equipment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreateTicketLocationStep_equipment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreateTicketPriorityStep_equipment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreateTicketDescriptionStep_equipment"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "QueryEquipmentSuccess",
      "abstractKey": null
    }
  ],
  "type": "QueryEquipmentResult",
  "abstractKey": "__isQueryEquipmentResult"
};

(node as any).hash = "1f234af4f80567d0883b3df7d003480d";

export default node;
