import { hasValue } from '@lego/mst-error-utilities';
import { useCallback } from 'react';
import { useAreaAndProcessContext } from '../../contexts/area';

export const useEquipmentValidator = () => {
  const context = useAreaAndProcessContext();
  return useCallback(
    (isActive: boolean | null, plantNumber: number | null) => {
      if (hasValue(isActive) && hasValue(plantNumber)) {
        if (plantNumber?.toString() !== context.selectedArea.plantId) {
          return 'onOtherPlant';
        } else if (isActive === false) {
          return 'inactive';
        } else {
          return 'valid';
        }
      }
    },
    [context.selectedArea.plantId]
  );
};
