/**
 * @generated SignedSource<<d9c9a70b12963d54b30bcc4728424269>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentList_equipment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentCard_equipment">;
  readonly " $fragmentType": "EquipmentList_equipment";
};
export type EquipmentList_equipment$key = {
  readonly " $data"?: EquipmentList_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentList_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentList_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentCard_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "cce0adad4575515fce4df4471bcace2d";

export default node;
