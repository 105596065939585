import { Box } from '@mui/material';
import { FC } from 'react';
import { useMachineHoursContext } from '../../contexts/add-machine-hours/add-machine-hours-context';
import { useTranslation } from '../../utility/i18n/translation';
import { FlowHeaderWithDiscardDialog } from '../shared/FlowHeaderWithDiscardDialog';

export const AddMachineHoursHeader: FC = () => {
  const { translate } = useTranslation();
  const { state } = useMachineHoursContext();

  const totalTimeInAdditions = state.additions
    .map((addition) => addition.minutesSpent)
    .reduce((prev, curr) => prev + curr, 0);

  const dirty = totalTimeInAdditions > 0 || state.deletions.length > 0;

  return (
    <Box style={{ marginBottom: 60 }}>
      <FlowHeaderWithDiscardDialog
        dirty={dirty}
        title={translate('ADD_MACHINE_HOURS.HEADER.TITLE', 'Add machine time')}
      />
    </Box>
  );
};
