/**
 * @generated SignedSource<<3402712dee71d7284f827b8c70a192b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryPlantInput = {
  plantNumber: number;
};
export type PlannedSpringChangeJobsTabQuery$variables = {
  input: QueryPlantInput;
};
export type PlannedSpringChangeJobsTabQuery$data = {
  readonly plant: {
    readonly data?: {
      readonly mouldsForSpringChange: ReadonlyArray<{
        readonly id: string;
        readonly shotsSinceLastSpringChange: number | null;
        readonly springChangeInterval: number | null;
        readonly " $fragmentSpreads": FragmentRefs<"PlannedSpringChangeJobRow_mould">;
      }> | null;
    };
  } | null;
};
export type PlannedSpringChangeJobsTabQuery = {
  response: PlannedSpringChangeJobsTabQuery$data;
  variables: PlannedSpringChangeJobsTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shotsSinceLastSpringChange",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "springChangeInterval",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equipmentNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlannedSpringChangeJobsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "plant",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Mould",
                    "kind": "LinkedField",
                    "name": "mouldsForSpringChange",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PlannedSpringChangeJobRow_mould"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryPlantSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlannedSpringChangeJobsTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "plant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Plant",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Mould",
                    "kind": "LinkedField",
                    "name": "mouldsForSpringChange",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "location",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Measurement",
                        "kind": "LinkedField",
                        "name": "weight",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitOfMeasurement",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "boxConcept",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MouldMachine",
                        "kind": "LinkedField",
                        "name": "mouldMachine",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualShots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LocalTimestamp",
                        "kind": "LinkedField",
                        "name": "shotsLastUpdatedDate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "elementsPerShot",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastSpringChangeShots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DimmingInfo",
                        "kind": "LinkedField",
                        "name": "dimmings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MouldTicket",
                        "kind": "LinkedField",
                        "name": "currentSpringChangeTicket",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ticketNumber",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryPlantSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2021a84cb2c35406fe1a0388848a5da8",
    "id": null,
    "metadata": {},
    "name": "PlannedSpringChangeJobsTabQuery",
    "operationKind": "query",
    "text": "query PlannedSpringChangeJobsTabQuery(\n  $input: QueryPlantInput!\n) {\n  plant(input: $input) {\n    __typename\n    ... on QueryPlantSuccess {\n      data {\n        mouldsForSpringChange {\n          id\n          shotsSinceLastSpringChange\n          springChangeInterval\n          ...PlannedSpringChangeJobRow_mould\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment PlannedSpringChangeJobRow_mould on Mould {\n  id\n  equipmentNumber\n  description\n  statusCode\n  location {\n    description\n    id\n  }\n  weight {\n    value\n    unitOfMeasurement\n  }\n  type\n  boxConcept\n  mouldMachine {\n    equipmentNumber\n    id\n  }\n  actualShots\n  shotsLastUpdatedDate {\n    date\n  }\n  elementsPerShot\n  lastSpringChangeShots\n  springChangeInterval\n  shotsSinceLastSpringChange\n  dimmings {\n    count\n  }\n  currentSpringChangeTicket {\n    ticketNumber\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dec3740905f56a88746f6afd9403f7d";

export default node;
