/**
 * @generated SignedSource<<9c8b00e390d4cfa879bb269dde35d6d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TDMouldDetails_equipment$data = {
  readonly tonSize?: string | null;
  readonly type?: string | null;
  readonly weight?: {
    readonly unitOfMeasurement: string;
    readonly value: number;
  } | null;
  readonly " $fragmentType": "TDMouldDetails_equipment";
};
export type TDMouldDetails_equipment$key = {
  readonly " $data"?: TDMouldDetails_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TDMouldDetails_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TDMouldDetails_equipment",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Measurement",
          "kind": "LinkedField",
          "name": "weight",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unitOfMeasurement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tonSize",
          "storageKey": null
        }
      ],
      "type": "Mould",
      "abstractKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "0edbdf2c7240c5214384239bc68f5c80";

export default node;
