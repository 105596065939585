/**
 * @generated SignedSource<<dee48ac8196ec332f2860e2cd1b6759e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketLocation_ticket$data = {
  readonly location: {
    readonly description: string;
  } | null;
  readonly sublocation: {
    readonly description?: string;
  } | null;
  readonly " $fragmentType": "TicketLocation_ticket";
};
export type TicketLocation_ticket$key = {
  readonly " $data"?: TicketLocation_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketLocation_ticket">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketLocation_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Sublocation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FreeTextSublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};
})();

(node as any).hash = "b1794709b1a79888deb9a4fd6974e51c";

export default node;
