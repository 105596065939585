/**
 * @generated SignedSource<<21b9cdebeac92980b83c23d8f712633e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearch_process$data = {
  readonly id: string;
  readonly tickets?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"TicketList_ticket">;
      };
    } | null>;
    readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_Extras_ticketsConnection">;
  } | null;
  readonly " $fragmentType": "TicketSearch_process";
};
export type TicketSearch_process$key = {
  readonly " $data"?: TicketSearch_process$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearch_process">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "tickets"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "skip"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tickets"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./TicketSearchRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "TicketSearch_process",
  "selections": [
    {
      "condition": "skip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "tickets",
          "args": [
            {
              "kind": "Variable",
              "name": "input",
              "variableName": "tickets"
            }
          ],
          "concreteType": "TicketsConnection",
          "kind": "LinkedField",
          "name": "__TicketSearch_process_tickets_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "TicketList_ticket"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TicketSearch_Extras_ticketsConnection"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Process",
  "abstractKey": null
};
})();

(node as any).hash = "01f343ea4b57828fa0438b0e579e27c6";

export default node;
