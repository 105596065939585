/**
 * @generated SignedSource<<bc01150737b9328820e406c7cea79d24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateTicketLocationStep_equipment$data = {
  readonly __typename: string;
  readonly id: string;
  readonly location: {
    readonly description: string;
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketSublocationComponent_equipment" | "LocationSelect_equipment">;
  readonly " $fragmentType": "CreateTicketLocationStep_equipment";
};
export type CreateTicketLocationStep_equipment$key = {
  readonly " $data"?: CreateTicketLocationStep_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketLocationStep_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateTicketLocationStep_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LocationSelect_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateTicketSublocationComponent_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "cf5b81d2d7be9efac073185c5ac5c83c";

export default node;
