import { Locale as DateFnsLocale } from 'date-fns';
import * as locales from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import cs from '../../assets/i18n/cs-CZ.json';
import da from '../../assets/i18n/da-DK.json';
import en from '../../assets/i18n/en-US.json';
import es from '../../assets/i18n/es-MX.json';
import hu from '../../assets/i18n/hu-HU.json';
import zh from '../../assets/i18n/zh-CN.json';
import { Language } from '../../migration/equipment-details/__generated__/PrintSparePartsLabelDialogMutation.graphql';

export enum Locale {
  DANISH = 'da',
  ENGLISH = 'en',
  HUNGARIAN = 'hu',
  CHINESE = 'zh',
  SPANISH = 'es',
  CZECH = 'cs',
}

export const localeToDateFnsLocale = (locale: Locale): DateFnsLocale => {
  if (locale === Locale.ENGLISH) {
    return locales.enUS;
  } else if (locale === Locale.CHINESE) {
    return locales.zhCN;
  }

  return locales[locale];
};

export const localeToRelayLanguage = (locale: Locale): Language => {
  switch (locale) {
    case Locale.CHINESE:
      return 'Chinese';
    case Locale.CZECH:
      return 'Czech';
    case Locale.DANISH:
      return 'Danish';
    case Locale.ENGLISH:
      return 'English';
    case Locale.HUNGARIAN:
      return 'Hungarian';
    case Locale.SPANISH:
      return 'Spanish';
  }
};

export const initI18n = (): void => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources: {
        en: { translation: en },
        da: { translation: da },
        hu: { translation: hu },
        es: { translation: es },
        zh: { translation: zh },
        cs: { translation: cs },
      },
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};
