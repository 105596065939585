/**
 * @generated SignedSource<<631d154d34247421289b8bafd0a0a955>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketStatus = "Closed" | "InProgress" | "NotStarted" | "Released" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RequiredDates_ticket$data = {
  readonly completedTimestamp: {
    readonly " $fragmentSpreads": FragmentRefs<"DateTimeWithCalendarIcon_localTimestamp">;
  } | null;
  readonly requiredEndTimestamp: {
    readonly date: string;
    readonly time: string;
    readonly " $fragmentSpreads": FragmentRefs<"DateTimeWithCalendarIcon_localTimestamp" | "RequiredDates_Countdown_timestamp">;
  } | null;
  readonly status: TicketStatus;
  readonly " $fragmentSpreads": FragmentRefs<"useTicketClosedText_ticket" | "useTicketFlowColor_ticket">;
  readonly " $fragmentType": "RequiredDates_ticket";
};
export type RequiredDates_ticket$key = {
  readonly " $data"?: RequiredDates_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequiredDates_ticket">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DateTimeWithCalendarIcon_localTimestamp"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequiredDates_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTicketFlowColor_ticket"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTicketClosedText_ticket"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimestamp",
      "kind": "LinkedField",
      "name": "requiredEndTimestamp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RequiredDates_Countdown_timestamp"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LocalTimestamp",
      "kind": "LinkedField",
      "name": "completedTimestamp",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};
})();

(node as any).hash = "75babb42f057164e3f54460834191a3e";

export default node;
