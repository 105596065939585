/**
 * @generated SignedSource<<7c2f93af65024b805a17c45ecabf748e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationMouldDeleteDimmingInput = {
  dimmingId: string;
};
export type DeleteDimmingDialogMutation$variables = {
  input: MutationMouldDeleteDimmingInput;
};
export type DeleteDimmingDialogMutation$data = {
  readonly mouldDeleteDimming: {
    readonly data?: {
      readonly dimmings: {
        readonly details: ReadonlyArray<{
          readonly id: string;
        }> | null;
      } | null;
    };
  } | null;
};
export type DeleteDimmingDialogMutation = {
  response: DeleteDimmingDialogMutation$data;
  variables: DeleteDimmingDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DimmingInfo",
  "kind": "LinkedField",
  "name": "dimmings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DimmingDetails",
      "kind": "LinkedField",
      "name": "details",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDimmingDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldDeleteDimming",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldDeleteDimmingSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDimmingDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldDeleteDimming",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldDeleteDimmingSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eeca0e9f89395265f7926113b123deb9",
    "id": null,
    "metadata": {},
    "name": "DeleteDimmingDialogMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDimmingDialogMutation(\n  $input: MutationMouldDeleteDimmingInput!\n) {\n  mouldDeleteDimming(input: $input) {\n    __typename\n    ... on MutationMouldDeleteDimmingSuccess {\n      data {\n        dimmings {\n          details {\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69386d8fcf60754e54e3a819ef4d5767";

export default node;
