/**
 * @generated SignedSource<<d893332af7c09858eaa42a87e11bb733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentDocumentsGroupSearch_equipment$data = {
  readonly documentGroups: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
  }>;
  readonly " $fragmentType": "EquipmentDocumentsGroupSearch_equipment";
};
export type EquipmentDocumentsGroupSearch_equipment$key = {
  readonly " $data"?: EquipmentDocumentsGroupSearch_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentDocumentsGroupSearch_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentDocumentsGroupSearch_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentGroup",
      "kind": "LinkedField",
      "name": "documentGroups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "614ad1d06d3cf67b2bca89549b9e8d2a";

export default node;
