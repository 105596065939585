/**
 * @generated SignedSource<<6d2dfd332458a6f4aa90d9f528d84fde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchEquipmentDialogLocationFilter_plant$data = {
  readonly locations: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
    readonly locationNumber: number;
  }> | null;
  readonly " $fragmentType": "SearchEquipmentDialogLocationFilter_plant";
};
export type SearchEquipmentDialogLocationFilter_plant$key = {
  readonly " $data"?: SearchEquipmentDialogLocationFilter_plant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchEquipmentDialogLocationFilter_plant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchEquipmentDialogLocationFilter_plant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "locations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Plant",
  "abstractKey": null
};

(node as any).hash = "d21b99ab7b91a2c2443f98b8196bf5b3";

export default node;
