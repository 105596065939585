/**
 * @generated SignedSource<<a86a6549020e9ac397bc962ed97f8f09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDimmingDialog_dimmingDetails$data = {
  readonly cavityNumber: number;
  readonly expectedRemovalDescription: string | null;
  readonly id: string;
  readonly isPermanent: boolean;
  readonly reason: string | null;
  readonly " $fragmentType": "EditDimmingDialog_dimmingDetails";
};
export type EditDimmingDialog_dimmingDetails$key = {
  readonly " $data"?: EditDimmingDialog_dimmingDetails$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDimmingDialog_dimmingDetails">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDimmingDialog_dimmingDetails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cavityNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedRemovalDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPermanent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "DimmingDetails",
  "abstractKey": null
};

(node as any).hash = "b5d423912323d8895604136992b510a7";

export default node;
