import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { useAddRepairDescriptionContext } from '../../contexts/add-repair-description/add-repair-description-context';
import { useTranslation } from '../../utility/i18n/translation';

export const ARDNavigationButtons: FC = () => {
  const { translate } = useTranslation();

  const {
    dispatch,
    state: { currentStep },
  } = useAddRepairDescriptionContext();

  const onNextPressed = () => {
    dispatch({ type: 'step_forward' });
  };

  const onBackPressed = () => {
    dispatch({ type: 'step_backward' });
  };

  const showBackButton = currentStep !== 'damage';
  const showNextButton = currentStep !== 'summary';

  return (
    <Grid container spacing={2}>
      {showBackButton && (
        <Grid item>
          <Button variant="text" color="primary" onClick={onBackPressed}>
            {translate('GENERIC_BUTTON_TEXTS.BACK', 'Back')}
          </Button>
        </Grid>
      )}
      {showNextButton && (
        <Grid item>
          <Button variant="outlined" color="primary" onClick={onNextPressed}>
            {translate('GENERIC_BUTTON_TEXTS.NEXT', 'Next')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
