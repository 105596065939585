import {
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useAuthContext } from '../contexts/AuthContext';
import { useDefaultSnackbarErrorHandler } from '../utility/errorSnackbar/errorCodeTranslations';

export const useGMQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const { authenticated } = useAuthContext();
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();

  return useQuery(query, {
    onError: showErrorSnackForStatusCode,
    skip: !authenticated,
    ...options,
  });
};

export const useGMLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();

  return useLazyQuery(query, {
    onError: showErrorSnackForStatusCode,
    ...options,
  });
};

export const useGMMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  const { showErrorSnackForStatusCode } = useDefaultSnackbarErrorHandler();
  return useMutation(mutation, {
    onError: showErrorSnackForStatusCode,
    ...options,
  });
};
