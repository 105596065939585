/**
 * @generated SignedSource<<ce5f6c354d1fcbf4dcff28fc6c0f0a9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentCard_Chips_equipment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CleaningPercentageChip_equipment" | "DimmingsCountChip_equipment" | "EquipmentGroupChip_equipment">;
  readonly " $fragmentType": "EquipmentCard_Chips_equipment";
};
export type EquipmentCard_Chips_equipment$key = {
  readonly " $data"?: EquipmentCard_Chips_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentCard_Chips_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentCard_Chips_equipment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DimmingsCountChip_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CleaningPercentageChip_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentGroupChip_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "8f34ac480f184ef28bdab11b6463ee00";

export default node;
