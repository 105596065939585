/**
 * @generated SignedSource<<e2a357fa7195b86a4929fdf7275d9fe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorDashboardQuery$variables = {};
export type OperatorDashboardQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DashboardHeader_viewer">;
  };
};
export type OperatorDashboardQuery = {
  response: OperatorDashboardQuery$data;
  variables: OperatorDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardHeader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OperatorDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employee",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "uri",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdc2f8807c7ae3886b89f1226d05bd72",
    "id": null,
    "metadata": {},
    "name": "OperatorDashboardQuery",
    "operationKind": "query",
    "text": "query OperatorDashboardQuery {\n  viewer {\n    ...DashboardHeader_viewer\n    id\n  }\n}\n\nfragment DashboardHeader_viewer on Viewer {\n  me {\n    firstName\n    lastName\n    ...EmployeeAvatar_employee\n    id\n  }\n}\n\nfragment EmployeeAvatar_employee on Employee {\n  firstName\n  lastName\n  image {\n    uri\n  }\n}\n"
  }
};
})();

(node as any).hash = "3742611db91d4539717c19f33aceefce";

export default node;
