/**
 * @generated SignedSource<<3b801846a96c09e39566064a8189269c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CMSTicketsPriorityInput = "Priority1" | "Priority2" | "Priority8" | "%future added value";
export type MutationCmsCreateEquipmentRepairTicketInput = {
  assignToSelf: boolean;
  description: string;
  equipmentId: string;
  priority: CMSTicketsPriorityInput;
  requiredEndDate?: string | null;
};
export type CmsMutationsEquipmentRepairMutation$variables = {
  input: MutationCmsCreateEquipmentRepairTicketInput;
};
export type CmsMutationsEquipmentRepairMutation$data = {
  readonly result: {
    readonly data?: {
      readonly ticketNumber: number | null;
    };
    readonly errorMessage?: string;
  } | null;
};
export type CmsMutationsEquipmentRepairMutation = {
  response: CmsMutationsEquipmentRepairMutation$data;
  variables: CmsMutationsEquipmentRepairMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ticketNumber",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "errorMessage",
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": "__isError"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CmsMutationsEquipmentRepairMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cmsCreateEquipmentRepairTicket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CMSTicket",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCmsCreateEquipmentRepairTicketSuccess",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CmsMutationsEquipmentRepairMutation",
    "selections": [
      {
        "alias": "result",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cmsCreateEquipmentRepairTicket",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CMSTicket",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationCmsCreateEquipmentRepairTicketSuccess",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f51d50d37974ba08af3005d5414553bd",
    "id": null,
    "metadata": {},
    "name": "CmsMutationsEquipmentRepairMutation",
    "operationKind": "mutation",
    "text": "mutation CmsMutationsEquipmentRepairMutation(\n  $input: MutationCmsCreateEquipmentRepairTicketInput!\n) {\n  result: cmsCreateEquipmentRepairTicket(input: $input) {\n    __typename\n    ... on MutationCmsCreateEquipmentRepairTicketSuccess {\n      data {\n        ticketNumber\n        id\n      }\n    }\n    ... on Error {\n      __isError: __typename\n      errorMessage: message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8204e7abc53cc8a21f0d186fad442b18";

export default node;
