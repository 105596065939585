/**
 * @generated SignedSource<<9cc0fa4f43f493cb1a94631e84262ed1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationCreateDocumentPresignedUrlInput = {
  documentId: string;
};
export type DocumentRow_getDocumentPresignedURLMutation$variables = {
  input: MutationCreateDocumentPresignedUrlInput;
};
export type DocumentRow_getDocumentPresignedURLMutation$data = {
  readonly createDocumentPresignedUrl: string | null;
};
export type DocumentRow_getDocumentPresignedURLMutation = {
  response: DocumentRow_getDocumentPresignedURLMutation$data;
  variables: DocumentRow_getDocumentPresignedURLMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "createDocumentPresignedUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentRow_getDocumentPresignedURLMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentRow_getDocumentPresignedURLMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "23e1c9c0911695049337ba830d08e7e3",
    "id": null,
    "metadata": {},
    "name": "DocumentRow_getDocumentPresignedURLMutation",
    "operationKind": "mutation",
    "text": "mutation DocumentRow_getDocumentPresignedURLMutation(\n  $input: MutationCreateDocumentPresignedUrlInput!\n) {\n  createDocumentPresignedUrl(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "f69ea39f00f7d86fde927ed55f7f210d";

export default node;
