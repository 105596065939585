/**
 * @generated SignedSource<<e186cc0f53fab8efec8c11e75da3d285>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentNumber_equipment$data = {
  readonly equipmentNumber: number;
  readonly " $fragmentType": "EquipmentNumber_equipment";
};
export type EquipmentNumber_equipment$key = {
  readonly " $data"?: EquipmentNumber_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentNumber_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentNumber_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentNumber",
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "98416dd4220e207ee8da4c95ffe1be8f";

export default node;
