/**
 * @generated SignedSource<<abbdc55ce855967b5ce5b860224deba0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SisterMouldCard_mould$data = {
  readonly equipmentNumber: number;
  readonly plant: {
    readonly " $fragmentSpreads": FragmentRefs<"AreaWithFlag_plant">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentLocation_equipment" | "MouldCavitiesWithDimmings_mould" | "MouldConstructionNumber_mould" | "MouldCurrentProductionEnd_mould" | "MouldNextProductionStart_mould" | "MouldStandardPartsPerHour_mould" | "MouldStatusChip_mould" | "MouldTonSize_mould" | "MouldType_mould" | "MouldWeight_mould" | "SisterMouldSpringChangeRow_springChange" | "SisterMouldSysCleaningRow_cleaning">;
  readonly " $fragmentType": "SisterMouldCard_mould";
};
export type SisterMouldCard_mould$key = {
  readonly " $data"?: SisterMouldCard_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"SisterMouldCard_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SisterMouldCard_mould",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquipmentLocation_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldCavitiesWithDimmings_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldConstructionNumber_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldCurrentProductionEnd_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldNextProductionStart_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldStandardPartsPerHour_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldStatusChip_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldTonSize_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldType_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MouldWeight_mould"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SisterMouldSysCleaningRow_cleaning"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SisterMouldSpringChangeRow_springChange"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Plant",
      "kind": "LinkedField",
      "name": "plant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AreaWithFlag_plant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentNumber",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "5581161f44298d05a0dafac7bd83a38e";

export default node;
