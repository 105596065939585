import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, ReactElement } from 'react';

export const PlannedJobsTable: FC<{
  columnHeaders: string[][];
  jobRows: ReactElement[];
}> = ({ columnHeaders, jobRows }) => {
  return (
    <TableContainer component={Paper} sx={{ p: 0, my: 3 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columnHeaders.map(([key, value]) => (
              <StyledTableCell align="center" key={key}>
                {value}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{jobRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
    fontWeight: 700,
  },
}));
