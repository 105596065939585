/**
 * @generated SignedSource<<5c4b4f2a64d5360b9f27263e2397db89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketNumber_ticket$data = {
  readonly ticketNumber: number | null;
  readonly " $fragmentType": "TicketNumber_ticket";
};
export type TicketNumber_ticket$key = {
  readonly " $data"?: TicketNumber_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketNumber_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketNumber_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketNumber",
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "c5f67df7622f480a34e6fdf2b01d1433";

export default node;
