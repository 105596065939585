import { useCallback } from 'react';
import { useApolloClient } from '../../apollo/apollo-client';
import { useAuthContext } from '../../contexts/AuthContext';

export const useLogout = (): { logOut: () => void } => {
  const { logOut: logoutFromAuth } = useAuthContext();

  const apolloClient = useApolloClient();

  const logOut = useCallback(() => {
    logoutFromAuth();
    apolloClient?.clearStore();

    // TODO: Clear user from analytics and Sentry once set up
  }, [apolloClient, logoutFromAuth]);

  return {
    logOut,
  };
};
