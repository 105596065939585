/**
 * @generated SignedSource<<b6013a5de78520400338a0c08a635627>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Language = "Chinese" | "Czech" | "Danish" | "English" | "Hungarian" | "Spanish" | "Vietnamese" | "%future added value";
export type MutationPrintSparePartLabelInput = {
  language: Language;
  printerId: string;
  sparePartId: string;
  storageLocationId: string;
};
export type PrintSparePartsLabelDialogMutation$variables = {
  input: MutationPrintSparePartLabelInput;
};
export type PrintSparePartsLabelDialogMutation$data = {
  readonly printSparePartLabel: {
    readonly __typename: "MutationPrintSparePartLabelSuccess";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type PrintSparePartsLabelDialogMutation = {
  response: PrintSparePartsLabelDialogMutation$data;
  variables: PrintSparePartsLabelDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrintSparePartsLabelDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printSparePartLabel",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v2/*: any*/),
            "type": "MutationPrintSparePartLabelSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrintSparePartsLabelDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "printSparePartLabel",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d121bbc0f0e716fc28e999e65f47bf8",
    "id": null,
    "metadata": {},
    "name": "PrintSparePartsLabelDialogMutation",
    "operationKind": "mutation",
    "text": "mutation PrintSparePartsLabelDialogMutation(\n  $input: MutationPrintSparePartLabelInput!\n) {\n  printSparePartLabel(input: $input) {\n    __typename\n    ... on MutationPrintSparePartLabelSuccess {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b15047be825ec3c8896e88cfa09c6ae6";

export default node;
