/**
 * @generated SignedSource<<7da3b7e15262f796dba6dda9cb171bb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateTicketDescriptionToggles_equipment$data = {
  readonly __typename: string;
  readonly " $fragmentType": "CreateTicketDescriptionToggles_equipment";
};
export type CreateTicketDescriptionToggles_equipment$key = {
  readonly " $data"?: CreateTicketDescriptionToggles_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketDescriptionToggles_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateTicketDescriptionToggles_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "bdb68b75c47ce1a6c4c8da5aec5c28c8";

export default node;
