/**
 * @generated SignedSource<<8183b1d8751534307cd9c96c6c607623>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateTicketSublocationComponent_equipment$data = {
  readonly __typename: string;
  readonly sublocation: {
    readonly __typename: "FreeTextSublocation";
    readonly description: string;
  } | {
    readonly __typename: "Sublocation";
    readonly description: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SublocationFreeText_equipment" | "SublocationSelect_equipment">;
  readonly " $fragmentType": "CreateTicketSublocationComponent_equipment";
};
export type CreateTicketSublocationComponent_equipment$key = {
  readonly " $data"?: CreateTicketSublocationComponent_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateTicketSublocationComponent_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateTicketSublocationComponent_equipment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "FreeTextSublocation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Sublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SublocationFreeText_equipment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SublocationSelect_equipment"
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "ed7ecf58c9a0c8dd6b8aa0e60449bb5c";

export default node;
