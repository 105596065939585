import { Grid, Paper, Typography } from '@mui/material';
import graphql from 'babel-plugin-relay/macro';
import { FC } from 'react';
import { useFragment } from 'react-relay';
import { SublocationItem_sublocation$key } from './__generated__/SublocationItem_sublocation.graphql';

export const SublocationItem: FC<{
  sublocation: SublocationItem_sublocation$key;
}> = ({ sublocation: sublocationRef }) => {
  const sublocation = useFragment(
    graphql`
      fragment SublocationItem_sublocation on Sublocation {
        code
        description
      }
    `,
    sublocationRef
  );

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Typography>{sublocation.code}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{sublocation.description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
