/**
 * @generated SignedSource<<7e301975b6778274916e93c75b936fce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MutationMouldUpdateStatusCodeInput = {
  mouldId: string;
  statusCode: number;
  updatedDate: LocalTimestampInput;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type MouldMiniOrderDialogMutation$variables = {
  input: MutationMouldUpdateStatusCodeInput;
};
export type MouldMiniOrderDialogMutation$data = {
  readonly mouldUpdateStatusCode: {
    readonly data?: {
      readonly " $fragmentSpreads": FragmentRefs<"MouldMiniOrderDialog_mould">;
    };
  } | null;
};
export type MouldMiniOrderDialogMutation = {
  response: MouldMiniOrderDialogMutation$data;
  variables: MouldMiniOrderDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MouldMiniOrderDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldUpdateStatusCode",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MouldMiniOrderDialog_mould"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldUpdateStatusCodeSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MouldMiniOrderDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "mouldUpdateStatusCode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Mould",
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "previousStatusCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationMouldUpdateStatusCodeSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc28e922ddb7ad8821f4cab0f1021a4d",
    "id": null,
    "metadata": {},
    "name": "MouldMiniOrderDialogMutation",
    "operationKind": "mutation",
    "text": "mutation MouldMiniOrderDialogMutation(\n  $input: MutationMouldUpdateStatusCodeInput!\n) {\n  mouldUpdateStatusCode(input: $input) {\n    __typename\n    ... on MutationMouldUpdateStatusCodeSuccess {\n      data {\n        ...MouldMiniOrderDialog_mould\n        id\n      }\n    }\n  }\n}\n\nfragment MouldMiniOrderDialog_mould on Mould {\n  id\n  previousStatusCode\n}\n"
  }
};
})();

(node as any).hash = "93ee07f01cd26c3766ef31ba55b1245b";

export default node;
