/**
 * @generated SignedSource<<d70a8d7fe65ef49408a15f9c782f049e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartsTabConsumed_ticket$data = {
  readonly spareParts: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SparePartsTabConsumedRow_ISparePartMovement">;
  }>;
  readonly " $fragmentType": "SparePartsTabConsumed_ticket";
};
export type SparePartsTabConsumed_ticket$key = {
  readonly " $data"?: SparePartsTabConsumed_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartsTabConsumed_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "spareParts"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartsTabConsumed_ticket",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "spareParts"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "spareParts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SparePartsTabConsumedRow_ISparePartMovement"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "65e2a2ae4754e6c3b58b2f786fc04bfc";

export default node;
