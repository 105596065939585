import { Grid, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../../utility/icons';

export const TicketIdWithIcon: FC<{ ticketId: string }> = ({ ticketId }) => {
  const {
    palette: { text },
  } = useTheme();
  return (
    <Grid container direction="row">
      <Icons.TicketId style={{ color: text.secondary, marginRight: 8 }} />
      <Typography variant="body1" color="textSecondary">
        {ticketId}
      </Typography>
    </Grid>
  );
};
