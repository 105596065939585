/**
 * @generated SignedSource<<5d855e4c4464a461bcc68c6143b3645b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationSelect_equipment$data = {
  readonly id: string;
  readonly location: {
    readonly description: string;
    readonly id: string;
    readonly locationNumber: number;
  } | null;
  readonly plant: {
    readonly locations: ReadonlyArray<{
      readonly description: string;
      readonly id: string;
      readonly locationNumber: number;
    }> | null;
  } | null;
  readonly " $fragmentType": "LocationSelect_equipment";
};
export type LocationSelect_equipment$key = {
  readonly " $data"?: LocationSelect_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationSelect_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locationNumber",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationSelect_equipment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Plant",
      "kind": "LinkedField",
      "name": "plant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Location",
          "kind": "LinkedField",
          "name": "locations",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "2984f07835b592ab963d41d015211e6f";

export default node;
