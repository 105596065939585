/**
 * @generated SignedSource<<7804712e13f3b6ccde5b92d22f7660c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SublocationFreeText_equipment$data = {
  readonly sublocation: {
    readonly description?: string;
  } | null;
  readonly " $fragmentType": "SublocationFreeText_equipment";
};
export type SublocationFreeText_equipment$key = {
  readonly " $data"?: SublocationFreeText_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SublocationFreeText_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SublocationFreeText_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "type": "FreeTextSublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "174683cff2602dc2e1ad664b3465f782";

export default node;
