/**
 * @generated SignedSource<<aab5bae34d2ad322e71ecd4d503178d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchEquipmentDialogRow_equipment$data = {
  readonly description: string;
  readonly equipmentNumber: number;
  readonly location: {
    readonly description: string;
    readonly locationNumber: number;
  } | null;
  readonly " $fragmentType": "SearchEquipmentDialogRow_equipment";
};
export type SearchEquipmentDialogRow_equipment$key = {
  readonly " $data"?: SearchEquipmentDialogRow_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchEquipmentDialogRow_equipment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchEquipmentDialogRow_equipment",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "equipmentNumber",
        "storageKey": null
      },
      "action": "THROW",
      "path": "equipmentNumber"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationNumber",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};
})();

(node as any).hash = "b09033854ea50c6e06e72af0f0ef5958";

export default node;
