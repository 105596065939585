/**
 * @generated SignedSource<<32db8e7c72d6310b129795ec81f94f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldNextProductionStart_mould$data = {
  readonly nextProductionOrder: {
    readonly plannedStartAt: {
      readonly date: string;
      readonly time: string;
    } | null;
    readonly priority: string | null;
  } | null;
  readonly " $fragmentType": "MouldNextProductionStart_mould";
};
export type MouldNextProductionStart_mould$key = {
  readonly " $data"?: MouldNextProductionStart_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldNextProductionStart_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldNextProductionStart_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductionOrder",
      "kind": "LinkedField",
      "name": "nextProductionOrder",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "priority",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LocalTimestamp",
          "kind": "LinkedField",
          "name": "plannedStartAt",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "d1a994fc9d96f7a1e1bbca7c7770b97d";

export default node;
