import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';

export const theme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: 'Lato',
    fontWeightBold: 700,
    // REM numbers from Figma px sizes and https://nekocalc.com/px-to-rem-converter for conversion
    h1: {
      fontSize: '2.5rem',
      fontWeight: 900,
      lineHeight: 48 / 16,
    },
    h2: {
      fontSize: '1.563rem',
      fontWeight: 900,
      lineHeight: 30 / 16,
    },
    h3: {
      fontSize: '1.375rem',
      fontWeight: 900,
      lineHeight: 26.4 / 16,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 900,
      lineHeight: 19.2 / 16,
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 28 / 16,
    },
    body1: {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: 28 / 16,
    },
    body2: {
      fontSize: '1.063rem',
      fontWeight: 400,
      lineHeight: 20.4 / 16,
      letterSpacing: '0.3px',
    },
    button: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 21.6 / 16,
      letterSpacing: '0.3px',
      textAlign: 'right',
    },
    caption: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 19.2 / 16,
      letterSpacing: '0.3px',
    },
  },
  palette: {
    primary: {
      main: '#006DB7',
    },
    secondary: {
      main: '#528BFF',
    },
    error: {
      main: '#D01012',
    },
    success: {
      main: '#009D2F',
    },
    background: {
      default: '#F3F3F3',
      paper: '#FFFFFF',
    },
    warning: {
      main: '#FFD500',
      dark: '#FF9800',
    },
    text: {
      disabled: '#D2D2D2',
      secondary: '#919191',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          padding: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 16,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: 2,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        whiteSpace: 'pre-line',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: 'black',
          fontWeight: 700,
          lineHeight: 28 / 16,
        },
      },
    },
  },
});
