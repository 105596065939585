/**
 * @generated SignedSource<<8e92b03438ddb44d3e2dd8ab8071d0a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HallMonitorItemRefetchQuery$variables = {
  id: string;
};
export type HallMonitorItemRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"HallMonitorItem_hallMonitorScreen">;
  } | null;
};
export type HallMonitorItemRefetchQuery = {
  response: HallMonitorItemRefetchQuery$data;
  variables: HallMonitorItemRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HallMonitorItemRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HallMonitorItem_hallMonitorScreen"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HallMonitorItemRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "HallMonitorScreenSection",
                "kind": "LinkedField",
                "name": "sections",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriorityFacet",
                    "kind": "LinkedField",
                    "name": "priorities",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "HallMonitorScreen",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9cf5e742ecccf2a339a388f4864d878",
    "id": null,
    "metadata": {},
    "name": "HallMonitorItemRefetchQuery",
    "operationKind": "query",
    "text": "query HallMonitorItemRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...HallMonitorItem_hallMonitorScreen\n    id\n  }\n}\n\nfragment HallMonitorItem_hallMonitorScreen on HallMonitorScreen {\n  title\n  sections {\n    ...HallMonitorItem_section\n  }\n  id\n}\n\nfragment HallMonitorItem_priority on PriorityFacet {\n  count\n  value\n}\n\nfragment HallMonitorItem_section on HallMonitorScreenSection {\n  title\n  priorities {\n    ...HallMonitorItem_priority\n    value\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "27c7d2f59c30c182eba6a794a94db98a";

export default node;
