/**
 * @generated SignedSource<<0e0272bb46779711a1456886dbe7f3f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueryTicketInput = {
  ticketNumber: number;
};
export type TicketMtsLogTabQuery$variables = {
  input: QueryTicketInput;
};
export type TicketMtsLogTabQuery$data = {
  readonly ticket: {
    readonly data?: {
      readonly equipment: {
        readonly " $fragmentSpreads": FragmentRefs<"MtsLogTabList_mould">;
      } | null;
    };
  };
};
export type TicketMtsLogTabQuery = {
  response: TicketMtsLogTabQuery$data;
  variables: TicketMtsLogTabQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Plant",
  "kind": "LinkedField",
  "name": "plant",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plantNumber",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rack",
  "storageKey": null
},
v9 = [
  (v4/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "sublocation",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": (v9/*: any*/),
      "type": "FreeTextSublocation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v9/*: any*/),
      "type": "Sublocation",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "MouldMachine",
  "kind": "LinkedField",
  "name": "mouldMachine",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equipmentNumber",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TicketMtsLogTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ticket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "equipment",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MtsLogTabList_mould"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TicketMtsLogTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ticket",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "equipment",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": (v12/*: any*/),
                            "concreteType": "MouldMtsLogConnection",
                            "kind": "LinkedField",
                            "name": "mtsLog",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MouldMtsLogConnectionEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MTSLog",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Employee",
                                        "kind": "LinkedField",
                                        "name": "employee",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstName",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastName",
                                            "storageKey": null
                                          },
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LocalTimestamp",
                                        "kind": "LinkedField",
                                        "name": "createdDate",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "date",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "time",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v10/*: any*/),
                                      (v11/*: any*/),
                                      (v3/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "mtsLog(first:20)"
                          },
                          {
                            "alias": null,
                            "args": (v12/*: any*/),
                            "filters": null,
                            "handle": "connection",
                            "key": "MtsLogTabList_mould_mtsLog",
                            "kind": "LinkedHandle",
                            "name": "mtsLog"
                          }
                        ],
                        "type": "Mould",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7ef3b11632dced25c23c93769ec29ef",
    "id": null,
    "metadata": {},
    "name": "TicketMtsLogTabQuery",
    "operationKind": "query",
    "text": "query TicketMtsLogTabQuery(\n  $input: QueryTicketInput!\n) {\n  ticket(input: $input) {\n    __typename\n    ... on QueryTicketSuccess {\n      data {\n        __typename\n        equipment {\n          __typename\n          ...MtsLogTabList_mould\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment AreaWithFlag_plant on Plant {\n  plantNumber\n}\n\nfragment MtsLogCard_mould on Mould {\n  location {\n    description\n    id\n  }\n  plant {\n    ...AreaWithFlag_plant\n    id\n  }\n  statusCode\n  rack\n  sublocation {\n    __typename\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Sublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  mouldMachine {\n    equipmentNumber\n    id\n  }\n}\n\nfragment MtsLogCard_mtsLog on MTSLog {\n  employee {\n    firstName\n    lastName\n    id\n  }\n  location {\n    description\n    id\n  }\n  plant {\n    ...AreaWithFlag_plant\n    id\n  }\n  createdDate {\n    date\n    time\n  }\n  statusCode\n  rack\n  sublocation {\n    __typename\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Sublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  mouldMachine {\n    equipmentNumber\n    id\n  }\n}\n\nfragment MtsLogTabList_mould on Mould {\n  ...MtsLogCard_mould\n  mtsLog(first: 20) {\n    edges {\n      node {\n        ...MtsLogCard_mtsLog\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "05c1f6fdf4ede6e332038563425f2ae7";

export default node;
