/**
 * @generated SignedSource<<d5c3918083e37d334f91df77d83b1165>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MouldStatusInput = "New" | "Operational" | "Other" | "Qualification" | "RampUp" | "Transit" | "Unknown" | "WornOut" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TicketSearchMouldStatusFilter_ticketsConnectionFacets$data = {
  readonly mouldStatus: ReadonlyArray<{
    readonly count: number;
    readonly value: MouldStatusInput;
  }> | null;
  readonly " $fragmentType": "TicketSearchMouldStatusFilter_ticketsConnectionFacets";
};
export type TicketSearchMouldStatusFilter_ticketsConnectionFacets$key = {
  readonly " $data"?: TicketSearchMouldStatusFilter_ticketsConnectionFacets$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchMouldStatusFilter_ticketsConnectionFacets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchMouldStatusFilter_ticketsConnectionFacets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MouldStatusCodeFacet",
      "kind": "LinkedField",
      "name": "mouldStatus",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnectionFacets",
  "abstractKey": null
};

(node as any).hash = "ba044366592beefdcef19824ee39b1cd";

export default node;
