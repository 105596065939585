/**
 * @generated SignedSource<<459b0beaa992462148303a00b09a7a60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldConstructionNumber_mould$data = {
  readonly constructionNumber: string | null;
  readonly " $fragmentType": "MouldConstructionNumber_mould";
};
export type MouldConstructionNumber_mould$key = {
  readonly " $data"?: MouldConstructionNumber_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldConstructionNumber_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldConstructionNumber_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constructionNumber",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "f1a2e6a72e1578b435b5e777216ab094";

export default node;
