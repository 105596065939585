import { gql } from '@apollo/client';
import { PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION } from '../../utility/permissions/ticket';
import { IMAGE_ROW_FRAGMENT } from '../shared/ImageRow';

export const TD_REPAIR_DOCUMENTATION_DAMAGE = gql`
  fragment RepairDocumentationDamage on RepairDocumentationDamage {
    damageId
    damageCodeText
    images {
      id
      ...ImageRowFragment
    }
    causes {
      description
    }
    activities {
      description
    }
    comments {
      description
      createdDate
    }
  }
  ${IMAGE_ROW_FRAGMENT}
`;

export const TD_REPAIR_DOCUMENTATION_SUBEQUIPMENT = gql`
  fragment RepairDocumentationSubEquipment on RepairDocumentationSubEquipment {
    code
    description
    components {
      code
      description
      damages {
        ...RepairDocumentationDamage
      }
    }
  }
`;

export const TD_REPAIR_DOCUMENTATION_WIDGET = gql`
  fragment RepairDocumentationWidget on RepairDocumentation {
    id
    damageList {
      ... on RepairDocumentationSubEquipmentList {
        subEquipments {
          ...RepairDocumentationSubEquipment
        }
      }

      ... on RepairDocumentationDamageList {
        damages {
          ...RepairDocumentationDamage
        }
      }
    }
  }
  ${TD_REPAIR_DOCUMENTATION_DAMAGE}
  ${TD_REPAIR_DOCUMENTATION_SUBEQUIPMENT}
`;

export const TD_REPAIR_DOCUMENTATION = gql`
  fragment TicketDetailsRepairDescriptions on Ticket {
    id
    ...CanAddRepairDescription
    repairDocumentation {
      id
      ...RepairDocumentationWidget
    }
  }
  ${TD_REPAIR_DOCUMENTATION_WIDGET}
  ${PERMISSIONS_CAN_ADD_REPAIR_DESCRIPTION}
`;
