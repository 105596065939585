/**
 * @generated SignedSource<<e964774e6da93bdc13fb63244c6e7c27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldStatusChip_mould$data = {
  readonly statusCode: number | null;
  readonly " $fragmentType": "MouldStatusChip_mould";
};
export type MouldStatusChip_mould$key = {
  readonly " $data"?: MouldStatusChip_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldStatusChip_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldStatusChip_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "360c32575fd4a5c1b9c85747b7a5cdab";

export default node;
