/**
 * @generated SignedSource<<f4527ec4f6d5dfab79e6e4f81f472372>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TicketAllowedCloseType = "Green" | "YellowToMPEE" | "YellowToProduction" | "%future added value";
export type QueryTicketInput = {
  ticketNumber: number;
};
export type CTChooseFlowQuery$variables = {
  input: QueryTicketInput;
};
export type CTChooseFlowQuery$data = {
  readonly ticket: {
    readonly data?: {
      readonly allowedCloseType?: ReadonlyArray<TicketAllowedCloseType> | null;
      readonly hasOtherTicketsInYellowFlow?: boolean | null;
    };
  };
};
export type CTChooseFlowQuery = {
  response: CTChooseFlowQuery$data;
  variables: CTChooseFlowQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedCloseType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasOtherTicketsInYellowFlow",
      "storageKey": null
    }
  ],
  "type": "MouldTicket",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CTChooseFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ticket",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "QueryTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CTChooseFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ticket",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "QueryTicketSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28459f9d22b8116d97c42a08488b474e",
    "id": null,
    "metadata": {},
    "name": "CTChooseFlowQuery",
    "operationKind": "query",
    "text": "query CTChooseFlowQuery(\n  $input: QueryTicketInput!\n) {\n  ticket(input: $input) {\n    __typename\n    ... on QueryTicketSuccess {\n      data {\n        __typename\n        ... on MouldTicket {\n          allowedCloseType\n          hasOtherTicketsInYellowFlow\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "169eb6d562bd64f11cca59b858c6b5cd";

export default node;
