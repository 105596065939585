/**
 * @generated SignedSource<<a4b58e27b5aea5976aada8e5fdd99312>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlantEquipmentSearchInput = {
  locationId?: string | null;
  searchTerm?: string | null;
};
export type SearchEquipmentDialogListRefetchQuery$variables = {
  after?: string | null;
  equipmentSearchInput: PlantEquipmentSearchInput;
  first?: number | null;
  id: string;
};
export type SearchEquipmentDialogListRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchEquipmentDialogList_plant">;
  } | null;
};
export type SearchEquipmentDialogListRefetchQuery = {
  response: SearchEquipmentDialogListRefetchQuery$data;
  variables: SearchEquipmentDialogListRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equipmentSearchInput"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locationNumber",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "equipmentSearchInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchEquipmentDialogListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "equipmentSearchInput",
                "variableName": "equipmentSearchInput"
              },
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SearchEquipmentDialogList_plant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchEquipmentDialogListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Location",
                "kind": "LinkedField",
                "name": "locations",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "EquipmentConnection",
                "kind": "LinkedField",
                "name": "equipmentSearch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EquipmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEquipment"
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "equipmentNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Location",
                            "kind": "LinkedField",
                            "name": "location",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v6/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "input"
                ],
                "handle": "connection",
                "key": "SearchEquipmentDialogList_equipmentSearch",
                "kind": "LinkedHandle",
                "name": "equipmentSearch"
              }
            ],
            "type": "Plant",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff0dc72dedb3cd99b7ad22b20ddbd3d1",
    "id": null,
    "metadata": {},
    "name": "SearchEquipmentDialogListRefetchQuery",
    "operationKind": "query",
    "text": "query SearchEquipmentDialogListRefetchQuery(\n  $after: ID\n  $equipmentSearchInput: PlantEquipmentSearchInput!\n  $first: Int = 20\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SearchEquipmentDialogList_plant_weoAT\n    id\n  }\n}\n\nfragment SearchEquipmentDialogList_plant_weoAT on Plant {\n  ...SearchEquipmentDialogLocationFilter_plant\n  equipmentSearch(first: $first, after: $after, input: $equipmentSearchInput) {\n    edges {\n      node {\n        __typename\n        id\n        ...SearchEquipmentDialogRow_equipment\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment SearchEquipmentDialogLocationFilter_plant on Plant {\n  locations {\n    id\n    description\n    locationNumber\n  }\n}\n\nfragment SearchEquipmentDialogRow_equipment on Equipment {\n  __isEquipment: __typename\n  description\n  equipmentNumber\n  location {\n    locationNumber\n    description\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9ce0687e2c73a948b3158d408da1245";

export default node;
