/**
 * @generated SignedSource<<e6ca9484aac752be83c362d69c9152c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MutationUpdateEquipmentLocationInput = {
  equipmentId: string;
  locationId: string;
  sublocationId?: string | null;
  sublocationText?: string | null;
  updatedDate: LocalTimestampInput;
};
export type LocalTimestampInput = {
  date: string;
  time: string;
};
export type CreateTicketLocationStepMutation$variables = {
  input: MutationUpdateEquipmentLocationInput;
};
export type CreateTicketLocationStepMutation$data = {
  readonly updateEquipmentLocation: {
    readonly data?: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateTicketLocationStep_equipment">;
    };
  } | null;
};
export type CreateTicketLocationStepMutation = {
  response: CreateTicketLocationStepMutation$data;
  variables: CreateTicketLocationStepMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locationNumber",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTicketLocationStepMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateEquipmentLocation",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CreateTicketLocationStep_equipment"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationUpdateEquipmentLocationSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTicketLocationStepMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateEquipmentLocation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "data",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEquipment"
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Plant",
                    "kind": "LinkedField",
                    "name": "plant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "locations",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "sublocation",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "type": "FreeTextSublocation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "type": "Sublocation",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Sublocation",
                    "kind": "LinkedField",
                    "name": "availableSublocations",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MutationUpdateEquipmentLocationSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd6f8230f457497cc7ccce7fdc548e63",
    "id": null,
    "metadata": {},
    "name": "CreateTicketLocationStepMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTicketLocationStepMutation(\n  $input: MutationUpdateEquipmentLocationInput!\n) {\n  updateEquipmentLocation(input: $input) {\n    __typename\n    ... on MutationUpdateEquipmentLocationSuccess {\n      data {\n        __typename\n        ...CreateTicketLocationStep_equipment\n        id\n      }\n    }\n  }\n}\n\nfragment CreateTicketLocationStep_equipment on Equipment {\n  __isEquipment: __typename\n  __typename\n  id\n  location {\n    id\n    description\n  }\n  ...LocationSelect_equipment\n  ...CreateTicketSublocationComponent_equipment\n}\n\nfragment CreateTicketSublocationComponent_equipment on Equipment {\n  __isEquipment: __typename\n  __typename\n  sublocation {\n    __typename\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Sublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...SublocationFreeText_equipment\n  ...SublocationSelect_equipment\n}\n\nfragment LocationSelect_equipment on Equipment {\n  __isEquipment: __typename\n  id\n  location {\n    id\n    description\n    locationNumber\n  }\n  plant {\n    locations {\n      id\n      description\n      locationNumber\n    }\n    id\n  }\n}\n\nfragment SublocationFreeText_equipment on Equipment {\n  __isEquipment: __typename\n  sublocation {\n    __typename\n    ... on FreeTextSublocation {\n      description\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment SublocationSelect_equipment on Equipment {\n  __isEquipment: __typename\n  sublocation {\n    __typename\n    ... on Sublocation {\n      description\n      id\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  availableSublocations {\n    id\n    description\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9194528c48a50c512dfd89aa35054b5";

export default node;
