import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

export const MtsLogCardDataRow: FC<{
  title: string;
  children: JSX.Element;
}> = ({ title, children }) => {
  return (
    <Grid container direction="row">
      <Grid item xs>
        <Typography>{title}:</Typography>
      </Grid>
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};
