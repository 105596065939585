import { gql } from '@apollo/client';
import { FC } from 'react';
import { useGMQuery } from '../../apollo/customApolloHooks';
import { useLogout } from '../profile/useLogout';
import { FillWidthLoading } from './FillWidthLoading';
import { ProfilePicture } from './__apollo__/ProfilePicture';

export const PROFILE_FRAGMENT_PICTURE_FRAGMENT = gql`
  fragment ProfileFragment on Profile {
    id
    firstName
    lastName
    profilePicture {
      id
      small
      large
    }
  }
`;

export const PROFILE_PICTURE_QUERY = gql`
  query ProfilePicture {
    me {
      id
      ...ProfileFragment
    }
  }
  ${PROFILE_FRAGMENT_PICTURE_FRAGMENT}
`;

export type PictureWithSignOutVariantProps = ProfilePicture & {
  onSignOutClicked: () => void;
};

type Props = {
  Component: FC<PictureWithSignOutVariantProps>;
};

export const PictureAndSignOut: FC<Props> = ({ Component }) => {
  const { logOut } = useLogout();

  const { data, loading } = useGMQuery<ProfilePicture>(PROFILE_PICTURE_QUERY);

  if (loading) {
    return <FillWidthLoading />;
  }

  if (!data) {
    return null;
  }

  return <Component me={data.me} onSignOutClicked={logOut} />;
};
