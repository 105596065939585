/**
 * @generated SignedSource<<44a2dc864aa77dfaa6d3529753ab11fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeAvatarWithName_employee$data = {
  readonly firstName: string;
  readonly lastName: string;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeAvatar_employee">;
  readonly " $fragmentType": "EmployeeAvatarWithName_employee";
};
export type EmployeeAvatarWithName_employee$key = {
  readonly " $data"?: EmployeeAvatarWithName_employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeAvatarWithName_employee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeAvatarWithName_employee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeAvatar_employee"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "924b725423f26ecd324a746269ad89e3";

export default node;
