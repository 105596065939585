import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../utility/icons';

export const ErrorMessage: FC<{ message: string }> = ({ message }) => {
  return (
    <Grid container alignItems="center" data-cy="ErrorMessage">
      <Grid item>
        <Icons.WarningTriangle color="error" />
      </Grid>
      <Grid item ml={1}>
        <Typography variant="body2" color="error">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};
