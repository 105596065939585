/**
 * @generated SignedSource<<64bd09349ea2c276a3e9429377d021a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DateTimeWithCalendarIcon_localTimestamp$data = {
  readonly date: string;
  readonly time: string;
  readonly " $fragmentType": "DateTimeWithCalendarIcon_localTimestamp";
};
export type DateTimeWithCalendarIcon_localTimestamp$key = {
  readonly " $data"?: DateTimeWithCalendarIcon_localTimestamp$data;
  readonly " $fragmentSpreads": FragmentRefs<"DateTimeWithCalendarIcon_localTimestamp">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DateTimeWithCalendarIcon_localTimestamp",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time",
      "storageKey": null
    }
  ],
  "type": "LocalTimestamp",
  "abstractKey": null
};

(node as any).hash = "74c7d54a21270e90500afeb6bbc56726";

export default node;
