/**
 * @generated SignedSource<<1cf663557f2d92bfebd467138d026950>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentGroupChip_equipment$data = {
  readonly group: {
    readonly description: string;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "EquipmentGroupChip_equipment";
};
export type EquipmentGroupChip_equipment$key = {
  readonly " $data"?: EquipmentGroupChip_equipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentGroupChip_equipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentGroupChip_equipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Equipment",
  "abstractKey": "__isEquipment"
};

(node as any).hash = "728b2d2634cac0c71f2575cefa1fb8e4";

export default node;
