/**
 * @generated SignedSource<<dd067774bb1c3c9981f730b911d59d76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearchLocationFilter_plant$data = {
  readonly locations: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
    readonly locationNumber: number;
  }> | null;
  readonly " $fragmentType": "TicketSearchLocationFilter_plant";
};
export type TicketSearchLocationFilter_plant$key = {
  readonly " $data"?: TicketSearchLocationFilter_plant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchLocationFilter_plant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchLocationFilter_plant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "locations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locationNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Plant",
  "abstractKey": null
};

(node as any).hash = "a52cccfc34c4b9677cb8d81f4c1aa50d";

export default node;
