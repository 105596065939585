/**
 * @generated SignedSource<<a29305783270af0eef47de18541491fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketPriority = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HallMonitorItem_section$data = {
  readonly priorities: ReadonlyArray<{
    readonly count: number;
    readonly value: TicketPriority;
    readonly " $fragmentSpreads": FragmentRefs<"HallMonitorItem_priority">;
  }>;
  readonly title: string;
  readonly " $fragmentType": "HallMonitorItem_section";
};
export type HallMonitorItem_section$key = {
  readonly " $data"?: HallMonitorItem_section$data;
  readonly " $fragmentSpreads": FragmentRefs<"HallMonitorItem_section">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HallMonitorItem_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "PriorityFacet",
        "kind": "LinkedField",
        "name": "priorities",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HallMonitorItem_priority"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "priorities"
    }
  ],
  "type": "HallMonitorScreenSection",
  "abstractKey": null
};

(node as any).hash = "e03035e10ae46988fdb6ddda38b3e995";

export default node;
