/**
 * @generated SignedSource<<d4efd96a9bd0245e602ef48bf98ea58b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MouldCavitiesWithDimmings_mould$data = {
  readonly dimmings: {
    readonly count: number | null;
  } | null;
  readonly elementsPerShot: number | null;
  readonly " $fragmentType": "MouldCavitiesWithDimmings_mould";
};
export type MouldCavitiesWithDimmings_mould$key = {
  readonly " $data"?: MouldCavitiesWithDimmings_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MouldCavitiesWithDimmings_mould">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MouldCavitiesWithDimmings_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementsPerShot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DimmingInfo",
      "kind": "LinkedField",
      "name": "dimmings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};

(node as any).hash = "8ccc9df34fd4f9d6d6c5e81674adfc02";

export default node;
