/**
 * @generated SignedSource<<46ca7aac9f9a13c8d1611f712a42f6d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MtsLogCard_mould$data = {
  readonly location: {
    readonly description: string;
  } | null;
  readonly mouldMachine: {
    readonly equipmentNumber: number;
  } | null;
  readonly plant: {
    readonly " $fragmentSpreads": FragmentRefs<"AreaWithFlag_plant">;
  } | null;
  readonly rack: string | null;
  readonly statusCode: number | null;
  readonly sublocation: {
    readonly description?: string;
  } | null;
  readonly " $fragmentType": "MtsLogCard_mould";
};
export type MtsLogCard_mould$key = {
  readonly " $data"?: MtsLogCard_mould$data;
  readonly " $fragmentSpreads": FragmentRefs<"MtsLogCard_mould">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MtsLogCard_mould",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Location",
      "kind": "LinkedField",
      "name": "location",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Plant",
      "kind": "LinkedField",
      "name": "plant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AreaWithFlag_plant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rack",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sublocation",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "FreeTextSublocation",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "Sublocation",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MouldMachine",
      "kind": "LinkedField",
      "name": "mouldMachine",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equipmentNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Mould",
  "abstractKey": null
};
})();

(node as any).hash = "f7003785021754e2c4f2b7ddbbb0fff0";

export default node;
