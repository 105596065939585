/**
 * @generated SignedSource<<b09f28124d09ce5e365ab8da6c4ac493>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TicketSearchHeader_ticketsConnection$data = {
  readonly count: number;
  readonly " $fragmentType": "TicketSearchHeader_ticketsConnection";
};
export type TicketSearchHeader_ticketsConnection$key = {
  readonly " $data"?: TicketSearchHeader_ticketsConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchHeader_ticketsConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchHeader_ticketsConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "type": "TicketsConnection",
  "abstractKey": null
};

(node as any).hash = "39ef1e483b4b0fec87bd20dbf005a5f0";

export default node;
