import { Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Icons } from '../../../utility/icons';

export const SparePartIdWithIcon: FC<{
  sparePartId: string;
  opaque?: boolean;
}> = ({ sparePartId, opaque }) => {
  const opacity = opaque ? 0.6 : 1;
  return (
    <Grid item container direction="row" alignItems="center">
      <Grid item>
        <Icons.SparePartCogs sx={{ mt: 0.5 }} fillOpacity={opacity} />
      </Grid>
      <Grid item>
        <Typography variant="body2" sx={{ opacity }}>
          {sparePartId}
        </Typography>
      </Grid>
    </Grid>
  );
};
