import { FC } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { Dashboard } from './Dashboard';
import { OperatorDashboard } from './OperatorDashboard';

export const DashboardWrapper: FC = () => {
  const { isRepairman } = useAuthContext();

  return isRepairman ? <Dashboard /> : <OperatorDashboard />;
};
