/**
 * @generated SignedSource<<9e9d94fb688882704ee3f8567e715f14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SparePartDetailsDialogStockLevels_StockRow_storageLocation$data = {
  readonly __typename: "StorageLocation";
  readonly description: string;
  readonly id: string;
  readonly stock: {
    readonly amount: number;
    readonly maxStock: number;
    readonly minimumStockBeforeReorder: number;
    readonly reservedAmount: number | null;
    readonly storageBin: string | null;
  } | null;
  readonly " $fragmentType": "SparePartDetailsDialogStockLevels_StockRow_storageLocation";
};
export type SparePartDetailsDialogStockLevels_StockRow_storageLocation$key = {
  readonly " $data"?: SparePartDetailsDialogStockLevels_StockRow_storageLocation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SparePartDetailsDialogStockLevels_StockRow_storageLocation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sparePartId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SparePartDetailsDialogStockLevels_StockRow_storageLocation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "sparePartId",
              "variableName": "sparePartId"
            }
          ],
          "kind": "ObjectValue",
          "name": "input"
        }
      ],
      "concreteType": "SparePartStock",
      "kind": "LinkedField",
      "name": "stock",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxStock",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "storageBin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimumStockBeforeReorder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reservedAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StorageLocation",
  "abstractKey": null
};

(node as any).hash = "caeb6990ea82e1b9f529c749be1f009c";

export default node;
