/**
 * @generated SignedSource<<7e391e8478fd3a6508f338c307f1e583>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketPriority = "Priority1" | "Priority2" | "Priority3" | "Priority4" | "Priority5" | "Priority6" | "Priority7" | "Priority8" | "Priority9" | "PriorityA" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EquipmentHistoryPriorityFilter_ticketsConnectionFacets$data = {
  readonly priority: ReadonlyArray<{
    readonly count: number;
    readonly value: TicketPriority;
  }> | null;
  readonly " $fragmentType": "EquipmentHistoryPriorityFilter_ticketsConnectionFacets";
};
export type EquipmentHistoryPriorityFilter_ticketsConnectionFacets$key = {
  readonly " $data"?: EquipmentHistoryPriorityFilter_ticketsConnectionFacets$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentHistoryPriorityFilter_ticketsConnectionFacets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentHistoryPriorityFilter_ticketsConnectionFacets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriorityFacet",
      "kind": "LinkedField",
      "name": "priority",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnectionFacets",
  "abstractKey": null
};

(node as any).hash = "83e124748b86030e8da72423a6c608e2";

export default node;
