/**
 * @generated SignedSource<<65d39441b1e57ec3eacc8ea6591eea1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FileType = "Excel" | "Html" | "Image" | "Mail" | "Movie" | "Pdf" | "PowerPoint" | "Txt" | "Unknown" | "Word" | "Zip" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DocumentRow_document$data = {
  readonly description: string;
  readonly id: string;
  readonly link: string | null;
  readonly name: string;
  readonly type: FileType;
  readonly " $fragmentType": "DocumentRow_document";
};
export type DocumentRow_document$key = {
  readonly " $data"?: DocumentRow_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentRow_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentRow_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "1ee4c03b99454c42e0fe16fb3eda5e27";

export default node;
