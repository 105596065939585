/**
 * @generated SignedSource<<129f51c9f966cdaab04d821dc229ef40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TicketStatus = "Closed" | "InProgress" | "NotStarted" | "Released" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TicketSearchStatusFilter_ticketsConnectionFacets$data = {
  readonly status: ReadonlyArray<{
    readonly count: number;
    readonly value: TicketStatus;
  }> | null;
  readonly " $fragmentType": "TicketSearchStatusFilter_ticketsConnectionFacets";
};
export type TicketSearchStatusFilter_ticketsConnectionFacets$key = {
  readonly " $data"?: TicketSearchStatusFilter_ticketsConnectionFacets$data;
  readonly " $fragmentSpreads": FragmentRefs<"TicketSearchStatusFilter_ticketsConnectionFacets">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TicketSearchStatusFilter_ticketsConnectionFacets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StatusFacet",
      "kind": "LinkedField",
      "name": "status",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketsConnectionFacets",
  "abstractKey": null
};

(node as any).hash = "24b0e572e829fc28c32d4f4d6eda81be";

export default node;
