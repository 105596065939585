import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

const size = 48;

export const StatusPill: FC<{
  statusCode: number;
  backgroundColor?: string;
}> = ({ statusCode, backgroundColor }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor ?? palette.text.disabled,
        height: size,
        width: size,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography color="white" variant="subtitle2" sx={{ fontSize: size / 3 }}>
        {statusCode}
      </Typography>
    </Box>
  );
};
