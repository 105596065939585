/**
 * @generated SignedSource<<11578a57973b72699cffde38c8af924c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useLabels_ticket$data = {
  readonly cost: {
    readonly total: {
      readonly amount: number;
      readonly currency: string;
    } | null;
  } | null;
  readonly spareParts: ReadonlyArray<{
    readonly quantity: number;
  }>;
  readonly timeRegistrations: {
    readonly timeSpent: string;
  } | null;
  readonly " $fragmentType": "useLabels_ticket";
};
export type useLabels_ticket$key = {
  readonly " $data"?: useLabels_ticket$data;
  readonly " $fragmentSpreads": FragmentRefs<"useLabels_ticket">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useLabels_ticket",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketTimeRegistrationsConnection",
      "kind": "LinkedField",
      "name": "timeRegistrations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeSpent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "input",
          "value": {
            "type": "CONSUMED"
          }
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "spareParts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": "spareParts(input:{\"type\":\"CONSUMED\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketCost",
      "kind": "LinkedField",
      "name": "cost",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "input",
              "value": {
                "local": true
              }
            }
          ],
          "concreteType": "Cost",
          "kind": "LinkedField",
          "name": "total",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": "total(input:{\"local\":true})"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ticket",
  "abstractKey": "__isTicket"
};

(node as any).hash = "0024c7ed7b5b0eb3070fa0b49e017639";

export default node;
