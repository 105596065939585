import { Container, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import pkg from '../../package.json';
import { AreaAndProcess } from '../components/profile/AreaAndProcess';
import { ChangeLanguage } from '../components/profile/ChangeLanguage';
import { ProfilePictureAndSignOutCard } from '../components/profile/ProfilePictureAndSignoutCard';
import { PictureAndSignOut } from '../components/shared/PictureAndSignOut';

export const ProfileContainer: FC = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} style={{ marginTop: 24 }}>
        <Grid item xs={12} lg={4}>
          <PictureAndSignOut Component={ProfilePictureAndSignOutCard} />
        </Grid>
        <Grid container direction="column" item xs={12} lg={8}>
          <Grid item>
            <ChangeLanguage />
          </Grid>
          <Grid item style={{ marginTop: 24 }}>
            <AreaAndProcess />
          </Grid>
          <Grid item style={{ alignSelf: 'flex-end' }}>
            <Typography variant="caption" style={{ opacity: 0.5 }}>
              {pkg.version}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
